.PlayerScreen {
  text-align: center;
  min-height: 90vh;
  font-size: calc(3vmin);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  
  color: white;
  list-style-type: none;
}

.PlayerScreenDuringPlay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (orientation: landscape) {
  .CardOnPlayerView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vh;
  }

  #showPreviousWindow {
    width: 8vw;
    height: 5vh;

    color: white;
    background-color: rgb(155,104,91);
  }

  #showNextWindow {
    width: 8vw;
    height: 5vh;

    color: white;
    background-color: rgb(155,104,91);
  }
}

@media only screen and (orientation: portrait) {
  .CardOnPlayerView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vmin;
  }

  #showPreviousWindow {
    width: 25vw;
    height: 3.5vh;

    color: white;
    background-color: rgb(155,104,91);
  }

  #showNextWindow {
    width: 25vw;
    height: 3.5vh;

    color: white;
    background-color: rgb(155,104,91);
  }
}

#showPreviousWindow:disabled {
  opacity: 0.75;
}

#showNextWindow:disabled {
  opacity: 0.75;
}

#playerHelpButton {
  font-size: 2.5rem;
}

.PlayButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4vmin;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.ChangeWindowButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
  gap: 10vw;
}
.Table {
  text-align: center;
  font-size: calc(3vmin);
  
  color: white;
  list-style-type: none;
}

.Home {
  text-align: center;
  font-size: calc(3vmin);
  
  color: white;
  list-style-type: none;

  min-height: 90vh;
}

.Options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  
.PlayerInfo {
  display: flex;
  flex-direction: row;
  gap: 0.5vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}

.PlayerInfoHighlighted {
  width: '108%';
  overflow:'visible';
  background-color: 'white';
  color: 'rgb(84,0,0)';
  font-weight: 'bold';
  border-top-right-radius: '50px';
  border-bottom-right-radius: '50px';
}

.ShowcasedPlayerCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5vmin;
}

#loadingShowcasedPlayerCard {
  width: 12vw;
  max-height: 27vh;
  aspect-ratio: 2 / 3;
  border-radius: 10px;
}

.PushToEndOfRow {
  margin-left: auto;
}

.StayInMiddleOfRow {
  position: fixed;
  left: 50%;
}

.CardOnTimeline {
  gap: 0.5vmin;
}

.ScoreboardInfo {
  display: flex;
  flex-direction: row;
  gap: 1.5vh;
}

#joinRoom {
  width: 12rem;
  height: 5rem;
  margin-bottom: 3rem;

  color: white;
  background-color: rgb(155,104,91);
}

#changeLanguage {
  width: 5rem;
  height: 2.5rem;
  margin-bottom: 3rem;

  color: white;
  background-color: rgb(155,104,91);
}

#createRoom {
  width: 12rem;
  height: 5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;

  color: white;
  background-color: rgb(155,104,91);
}

#connectToRoom {
  width: 12rem;
  height: 3rem;
  margin-bottom: 5rem;

  color: white;
  background-color: rgb(155,104,91);
}

#titleText {
  background-image: url("../assets/titleTextAlphaVersion.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30rem;
  height: 12rem;
  max-width: 100vw;
}

#startGame {
  width: 10rem;
  height: 3.3rem;

  color: white;
  background-color: rgb(155,104,91);
}

#startGame:disabled {
  opacity: 0.5;
}

.RowsOnHostView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  gap: 2vh;
}

.ListOfPlayersInRoom {
  gap: 4.5vh;
  width: 50vw;
  min-height: 85vh;
}

#joinTableScreen {
  gap: 3vh;
  height: 90vh;
}

.ColumnFlexWithCenteredItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RowFlexWithCenteredItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Gap7vw {
  gap: 7vw;
}

#logoAboveTimeline {
  background-image: url("../assets/titleTextAlphaVersion.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 10em;
  height: 7vh;
}

#aboveTimeline {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: min(90vw, 120vmin);
}

.Timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.75vh;

  height: 37vh;

  overflow: hidden;
}

.TopHalfOnHostView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
}

.BottomHalfOnHostView {
  display: flex;

  max-width: 95vw;
  max-height: 35vh;

  gap: 1.5vw;  
}

#topOfBHLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.BHLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: min(35vw, 47.75vmin);

  overflow: hidden;

  padding-bottom: 2vh;
  padding-right: 1em;
  padding-left: 2vw;
  
  background-color: rgb(60,0,0);
  border-radius: 20px;

  overflow: visible;
}

#questionAndTimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BHRight {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5vw;

  background-color: white;
  border-radius: 20px;

  width: min(55vw, 75vmin);

  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-right: 1vw;
  padding-left: 1vw;
}
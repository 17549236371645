html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url("../assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #520000;

  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
